import React from "react";

import IconButton from "@mui/material/IconButton";

import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

interface ABProps {
  onClick: (name: string, index: number, type: string) => void;
  index: number;
  name: string;
}

const AdjustButton = (props: ABProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "auto",
      }}
    >
      <IconButton
        onClick={() => props.onClick(props.name, props.index, "add")}
        sx={{ margin: "0px", padding: "0px" }}
        size="small"
      >
        <AddBoxIcon fontSize="inherit" />
      </IconButton>
      <IconButton
        onClick={() => props.onClick(props.name, props.index, "sub")}
        sx={{ margin: "0px", padding: "0px" }}
        size="small"
      >
        <IndeterminateCheckBoxIcon fontSize="inherit" />
      </IconButton>
    </div>
  );
};

export default AdjustButton;
