export const en = {
  translation: {
    HELLO: "Hello",
    SIGNUP: "Signup",
    PASSWORD_MUST_HAVE: "Password must fulfill the following conditions: ",
    PASS_CHARS: "min. eight characters long",
    LOWER_CASE: "min. one lower case letter",
    UPPER_CASE: "min. one upper case letter",
    DIGIT: "min. one digit",
    SPECIAL_CHAR: "min. one special char",
    SUBMIT: "Submit",
    TERMS_OF_SERVICE: "terms of service",
    VIEW_OUR: "View our ",
    EMAIL: "email",
    PASSWORD: "password",
    CONFIRM_PASSWORD: "confirm-password",
    CITY: "City",
    COUNTRY: "Country",
    RIVER: "River",
    LOGIN: "Login",
    NAME: "Name",
    ANIMAL: "Animal",
    PROFESSION: "Profession",
    HOME: "Home",
    CATEGORY: "Category",
    BUILD: "Build",
    SAVE: "Save",
    ADD: "Add",
    SHEET_SAVED: "Sheet saved",
    YOUR_SHEET_ID: "Your sheet id: ",
    CANCEL: "CANCEL",
    OK: "OK",
    INPUT_ID_HERE: "Fill your id here, please",
    DICE: "Dice",
    START_GAME: "Start game",
    CREATE_GAME: "Create game",
    DONE: "Done",
    WRONG_PASSWORD: "Wrong password",
    TRY_AGAIN: "Please try again.",
    ERROR: "Error",
    TOO_MANY_REQUESTS: "Too many requests",
    TRY_AGAIN_LATER: "Please try again later.",
    SOMETHING_WRONG: "Something went wrong.",
    NEXT_ROUND: "Next round",
    LETTER: "Letter",
    PLAYER: "Player",
    SUM: "Sum",
    TOTAL: "Total",
    ROUNDS: "Rounds",
    SCORE: "Score",
    CALC_SCORE: "Calculating score...",
    ROUND: "Round",
    END_GAME: "Finish game",
    CONGRATS: "Congratulations",
    YOU_WIN: "You win",
    WAIT_FOR_BEGIN: "Waiting for start",
    WELCOME: "Welcome",
    TO: "to",
  },
};
