import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

// i18n
import { useTranslation } from "react-i18next";

// CSS
import classes from "./Welcome.module.css";

// material-ui components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { RootState } from "store/store";

// custom components
import Logo from "components/Logo/Logo";

import { adminUid } from "constants/constants";

const Welcome = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const uid = useSelector((state: RootState) => state.auth.uid);

  const startGameHandler = () => {
    navigate("/start-game");
  };

  const startBuildSheetHandler = () => {
    navigate("/sheet-builder");
  };
  return (
    <div className={classes.Container}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100vh",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
      ></div>

      <div className={classes.ButtonContainer}>
        <Button
          className={classes.Button}
          variant="contained"
          onClick={startGameHandler}
        >
          {t("START_GAME")}
        </Button>
        {adminUid === uid && (
          <Button
            className={classes.Button}
            variant="contained"
            size="large"
            onClick={startBuildSheetHandler}
          >
            {t("CREATE_GAME")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Welcome;
