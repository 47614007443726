import React, { useEffect, useState, useCallback } from "react";

// firebase
import { doc, onSnapshot, setDoc, collection } from "firebase/firestore";
import { db } from "utils/firebase";

// redux
import { useSelector } from "react-redux";
import { RootState } from "store/store";

import { useNavigate } from "react-router-dom";

// material-ui components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Badge from "@mui/material/Badge";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

// custom components
import AdjustButton from "components/CustomButtons/AdjustButton";

// i18n
import { useTranslation } from "utils/i18n";

import classes from "./ScoreBoard.module.css";

import { adminUid } from "constants/constants";

interface User {
  name: string;
  items: Array<string>;
  key: string;
}

interface ResultsObject {
  users: Array<User>;
  score: Array<PointsObject>;
}

interface PointsObject {
  name: string;
  points: Array<number>;
  sum: number;
}

interface ScoresObject {
  [key: string]: Array<PointsObject>;
}

const sheetRef = collection(db, "sheets");

const ScoreBoard = () => {
  const [gameData, setGameData] = useState<ResultsObject | null>(null);
  const [currentLetter, setCurrentLetter] = useState<string>();
  const [pointsOA, setPointsOA] = useState<Array<PointsObject>>();
  const [scores, setScores] = useState<ScoresObject>();
  const [rounds, setRounds] = useState<number>();
  const [roundNo, setRoundNo] = useState<number>(1);
  const [scoreChanged, setScoreChanged] = useState<boolean>(false);

  const { t } = useTranslation();

  const uid = useSelector((state: RootState) => state.auth.uid);
  const gameId = useSelector((state: RootState) => state.game.gameId);
  const categories = useSelector((state: RootState) => state.game.categories);

  const navigate = useNavigate();

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "sheets", gameId), (doc) => {
      const newData = doc.data();

      let resultObject = null;
      if (newData) {
        if (newData.gameEnd) {
          navigate("/end-game");
        }
        if (newData.gameRunning) {
          navigate("/game");
        }

        setCurrentLetter(newData.currentLetter);
        setRounds(newData.rounds);
        setRoundNo(newData.usedLetters.length);
        console.log("NEW_DATA: ", newData);
        const results = { ...newData.results };

        if (results) {
          console.log("RESULTS: ", results);
          let letterObjects = [];
          for (let user in results[newData.currentLetter]) {
            letterObjects.push({
              name: user.split("-")[0],
              items: results[newData.currentLetter][user],
              key: user,
            });
          }
          resultObject = {
            users: letterObjects,
            score: newData.scores
              ? newData.scores[newData.currentLetter]
              : null,
          };
          if (
            !gameData ||
            (gameData && !gameData.users) ||
            (gameData.users.length > 0 &&
              resultObject.users.length !== gameData?.users.length)
          ) {
            setGameData(resultObject);
          }
        }
        if (newData.scores) {
          setScores(newData.scores);
        }
      }
    });
    return () => {
      unsub && unsub();
    };
  }, []);

  const onAdjustHandler = async (name: string, index: number, type: string) => {
    setScoreChanged(true);
    if (pointsOA && currentLetter) {
      const idx = pointsOA?.findIndex(
        (object: PointsObject) => object.name === name
      );
      const scoreArray = [...pointsOA[idx].points];
      let score = scoreArray[index];
      if (type === "add" && score < 30) {
        score = score + 10;
      } else if (type === "sub" && score >= 10) {
        score = score - 10;
      }
      scoreArray[index] = score;
      const updatedPointsOA = [...pointsOA];
      updatedPointsOA[idx].points = scoreArray;
      setPointsOA(updatedPointsOA);

      try {
        const docRef = await setDoc(
          doc(sheetRef, gameId),
          {
            scores: {
              [currentLetter]: updatedPointsOA,
            },
          },

          { merge: true }
        );
      } catch (err) {
        console.log("ERR: ", err);
      }
    }
  };

  const calculatePointsHandler = useCallback(async () => {
    if (gameData && currentLetter && !scoreChanged) {
      let pointsObjectArray = [];
      if (gameData.users.length > 1) {
        for (let i = 0; i < gameData.users.length; i++) {
          const pointsObject = {
            name: gameData.users[i].name,
            points: [30],
            sum: 0,
          };
          const opponentsArray = gameData.users.filter(
            (user, index) => index !== i
          );
          for (let j = 0; j < opponentsArray.length; j++) {
            const opponentsAnswers = [...opponentsArray[j].items];
            for (let k = 0; k < opponentsAnswers.length; k++) {
              let p = 30;
              if (opponentsAnswers[k].length > 1) {
                p = 20;
              }
              if (
                gameData.users[i].items[k].toUpperCase() ===
                opponentsAnswers[k].toUpperCase()
              ) {
                p = 10;
              }
              if (gameData.users[i].items[k].length < 2) {
                p = 0;
              }
              pointsObject.points[k] =
                pointsObject.points[k] < p ? pointsObject.points[k] : p;
            }
          }

          let sum = 0;
          for (let s = 0; s < pointsObject.points.length; s++) {
            sum += pointsObject.points[s];
          }
          pointsObject.sum = sum;

          if (pointsObjectArray.length > 0) {
            let pIndex = pointsObjectArray.length;
            for (let x = 0; x < pointsObjectArray.length; x++) {
              if (pointsObjectArray[x].sum < pointsObject.sum) {
                pIndex = pIndex < x ? pIndex : x;
              } else if (x + 1 === pointsObjectArray.length) {
                pIndex = pointsObjectArray.length;
              }
            }

            pointsObjectArray.splice(pIndex, 0, pointsObject);
          } else {
            pointsObjectArray.push(pointsObject);
          }
        }
      }
      setPointsOA(pointsObjectArray);

      try {
        const docRef = await setDoc(
          doc(sheetRef, gameId),
          {
            scores: {
              [currentLetter]: pointsObjectArray,
            },
          },

          { merge: true }
        );
      } catch (err) {
        console.log("ERR: ", err);
      }
    }
  }, [gameData]);

  useEffect(() => {
    if (adminUid === uid && gameData) {
      calculatePointsHandler();
    }
  }, [adminUid, uid, gameData, calculatePointsHandler]);

  const startNextRoundHandler = async () => {
    try {
      if (rounds && rounds <= roundNo) {
        const docRef = await setDoc(
          doc(sheetRef, gameId),
          {
            gameEnd: true,
          },
          { merge: true }
        );
      } else {
        const docRef = await setDoc(
          doc(sheetRef, gameId),
          {
            gameStopped: false,
          },
          { merge: true }
        );

        navigate("/game");
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  return (
    <div className={classes.Container}>
      {gameData && scores ? (
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <Paper className={classes.Paper}>
              <TableContainer>
                {gameData && scores && currentLetter && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {t("LETTER")} "{currentLetter}"
                        </TableCell>
                        <TableCell>
                          {t("ROUND") + " " + roundNo + " / " + rounds}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">{t("PLAYER")}</TableCell>
                        {categories.map((category: string, index: number) => {
                          return (
                            <TableCell align="left" key={category}>
                              {category.toUpperCase()}
                            </TableCell>
                          );
                        })}
                        <TableCell>{t("SUM")}</TableCell>
                        <TableCell>{t("TOTAL")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {scores &&
                        scores[currentLetter] &&
                        scores[currentLetter].map(
                          (points: PointsObject, key: number) => {
                            const userItems = gameData.users.filter(
                              (user: User) => user.name === points.name
                            )[0].items;

                            let sum = 0;

                            for (let p of points.points) {
                              sum = sum + p;
                            }

                            let total = 0;

                            if (scores) {
                              for (let letter in scores) {
                                for (
                                  let r = 0;
                                  r < scores[letter].length;
                                  r++
                                ) {
                                  if (scores[letter][r].name === points.name) {
                                    for (
                                      let t = 0;
                                      t < scores[letter][r].points.length;
                                      t++
                                    ) {
                                      total =
                                        total + scores[letter][r].points[t];
                                    }
                                  }
                                }
                              }
                            }
                            return (
                              <TableRow key={points.name + key}>
                                <TableCell align="left">
                                  {points.name}
                                </TableCell>
                                {points.points.map(
                                  (score: number, key: number) => {
                                    return (
                                      <TableCell
                                        align="left"
                                        key={points.name + "score" + key}
                                      >
                                        <Badge
                                          color={
                                            score > 0 ? "primary" : "secondary"
                                          }
                                          badgeContent={score}
                                          //overlap="circular"
                                          showZero
                                        >
                                          <div style={{ paddingRight: "10px" }}>
                                            {userItems[key]}
                                          </div>
                                        </Badge>
                                      </TableCell>
                                    );
                                  }
                                )}
                                <TableCell>{sum}</TableCell>
                                <TableCell>{total}</TableCell>
                              </TableRow>
                            );
                          }
                        )}
                    </TableBody>
                  </Table>
                )}
                {adminUid === uid &&
                  pointsOA &&
                  pointsOA.map((points: PointsObject, key: number) => {
                    return (
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("NAME")}</TableCell>
                            {categories.map((category: string, key: number) => {
                              return (
                                <TableCell key={category + key}>
                                  {category}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{points.name}</TableCell>
                            {points.points.map((score: number, key: number) => {
                              return (
                                <TableCell>
                                  <div className={classes.ScoreContainer}>
                                    {score}
                                    <AdjustButton
                                      name={points.name}
                                      index={key}
                                      onClick={onAdjustHandler}
                                    />
                                  </div>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableBody>
                      </Table>
                    );
                  })}
              </TableContainer>
              {adminUid === uid && (
                <>
                  <Button variant="contained" onClick={startNextRoundHandler}>
                    {roundNo !== rounds ? t("NEXT_ROUND") : t("END_GAME")}
                  </Button>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.Calculate}>
          <Typography variant="h2" color="#ffffff">
            {t("CALC_SCORE")}
          </Typography>
          <CircularProgress size={100} />
        </div>
      )}
    </div>
  );
};

export default ScoreBoard;
