import React from "react";

import classes from "./BuzzerButton.module.css";

// i18n
import { useTranslation } from "utils/i18n";

interface BuzzerProps {
  onClick: () => void;
  disabled: boolean;
}

const BuzzerButton = (props: BuzzerProps) => {
  const { t } = useTranslation();

  return (
    <div className={classes.ButtonContainer}>
      <button
        onClick={props.onClick}
        disabled={props.disabled}
        className={classes.Button}
      >
        {t("DONE")}
      </button>
    </div>
  );
};

export default BuzzerButton;
