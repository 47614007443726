export const de = {
  translation: {
    HELLO: "Hallo",
    SIGNUP: "Registrieren",
    PASSWORD_MUST_HAVE: "Das Passwort muss folgende Bedingungen erfüllen: ",
    PASS_CHARS: "min. acht Zeichen lang",
    LOWER_CASE: "min. einen Kleinbuchstaben",
    UPPER_CASE: "min. einen Großbuchstaben",
    DIGIT: "min. eine Zahl",
    SPECIAL_CHAR: "min. ein Sonderzeichen",
    SUBMIT: "Absenden",
    TERMS_OF_SERVICE: "AGB",
    VIEW_OUR: "Sehen Sie unsere ",
    EMAIL: "Email",
    PASSWORD: "Passwort",
    CONFIRM_PASSWORD: "Passwort bestätigen",
    CITY: "Stadt",
    COUNTRY: "Land",
    RIVER: "Fluss",
    LOGIN: "Einloggen",
    NAME: "Name",
    ANIMAL: "Tier",
    PROFESSION: "Beruf",
    HOME: "Home",
    CATEGORY: "Kategorie",
    BUILD: "Erstellen",
    SAVE: "Speichern",
    ADD: "Hinzufügen",
    SHEET_SAVED: "Blatt gespeichert",
    YOUR_SHEET_ID: "Deine Blatt ID: ",
    CANCEL: "ABBRECHEN",
    OK: "OK",
    INPUT_ID_HERE: "Gib hier bitte deine ID ein",
    DICE: "Würfeln",
    START_GAME: "Spiel starten",
    CREATE_GAME: "Spiel erstellen",
    DONE: "Fertig",
    WRONG_PASSWORD: "Falsches Passwort",
    TRY_AGAIN: "Versuche es bitte noch einmal.",
    ERROR: "Fehler",
    TOO_MANY_REQUESTS: "Zu viele Versuche",
    TRY_AGAIN_LATER: "Bitte versuche es später wieder.",
    SOMETHING_WRONG: "Etwas ist schief gelaufen.",
    NEXT_ROUND: "Nächste Runde",
    LETTER: "Buchstabe",
    PLAYER: "Spieler",
    SUM: "Gesamt",
    TOTAL: "Total",
    ROUNDS: "Runden",
    SCORE: "Punkte",
    CALC_SCORE: "Berechne Score...",
    ROUND: "Runde",
    END_GAME: "Spiel beenden",
    CONGRATS: "Glückwunsch",
    YOU_WIN: "Du hast gewonnen",
    WAIT_FOR_BEGIN: "Warte auf Spielstart",
    WELCOME: "Willkommen",
    TO: "zu",
  },
};
