import React, { useState } from "react";
import "./App.css";

// hoc
import withGlobalAlert from "hoc/withGlobalAlert";
// react-router
import { BrowserRouter, Routes, Route } from "react-router-dom";

//redux
import { RootState } from "store/store";
import { useSelector } from "react-redux";

// material-ui theming
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";

// context
import { ThemeContext } from "context/themeContext";

// import roboto font
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

//components
import Drawer from "components/Drawer/Drawer";

// views
import Welcome from "views/Welcome/Welcome";
import Auth from "views/Auth/Auth";
import SheetBuilder from "views/SheetBuilder/SheetBuilder";
import Game from "views/Game/Game";
import StartGame from "views/StartGame/StartGame";
import Home from "views/Home/Home";
import ScoreBoard from "views/ScoreBoard/ScoreBoard";
import EndGameScreen from "views/EndGameScreen/EndGameScreen";
import WaitGame from "views/WaitGame/WaitGame";

//import Dekko from "assets/fonts/Dekko/Dekko-Regular.ttf";
declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

function App() {
  const [mode, setMode] = useState<"light" | "dark">("light");

  const userId = useSelector((state: RootState) => state.auth.uid);

  const themeMode = React.useMemo(
    () => ({
      toggleTheme: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
    palette: {
      mode: mode,
      primary: {
        main: "#89cff0",
      },
      /*    secondary: {
        main: "#d4d46a",
      }, */
    },
    typography: {
      fontFamily: "Poppins",
    },
  });

  theme.typography.h1 = {
    fontFamily: "Dekko",
    fontSize: "6rem",
    fontWeight: 400,
  };
  theme.typography.h2 = {
    fontFamily: "Dekko",
    fontSize: "4rem",
    fontWeight: 400,
  };

  return (
    <ThemeContext.Provider value={themeMode}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Drawer />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/welcome" element={<Welcome />} />
            {userId && (
              <>
                <Route path="/sheet-builder" element={<SheetBuilder />} />
                <Route path="/start-game" element={<StartGame />} />
                <Route path="/game" element={<Game />} />
                <Route path="/score-board" element={<ScoreBoard />} />
                <Route path="/end-game" element={<EndGameScreen />} />
                <Route path="/wait-game" element={<WaitGame />} />
              </>
            )}
            <Route path="/*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default withGlobalAlert(App);
