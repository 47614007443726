import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

// redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";

// material-ui components
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Button from "@mui/material/Button";

// material-ui icons
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import LoginIcon from "@mui/icons-material/Login";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import LogoutIcon from "@mui/icons-material/Logout";

// custom components
import LanguageSwitch from "components/LanguageSwitch/LanguageSwitch";
import ThemeSwitch from "components/CustomButtons/ThemeSwitch";

// i18n
import { useTranslation } from "utils/i18n";

// theme context
import { ThemeContext } from "context/themeContext";

import { logout } from "store/authReducer";

import { adminUid } from "constants/constants";

import classes from "./Drawer.module.css";

export default function SideDrawer() {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [themeMode, setThemeMode] = useState<boolean>(false);

  const name = useSelector((state: RootState) => state.auth.name);
  const uid = useSelector((state: RootState) => state.auth.uid);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { toggleTheme } = useContext(ThemeContext);

  const switchThemeMode = () => {
    setThemeMode((prev) => !prev);
    toggleTheme();
  };

  const toggleDrawer = (open: boolean) => {
    setShowDrawer(open);
  };

  const logoutHandler = () => {
    localStorage.removeItem("loginData");
    dispatch(logout());
    navigate("/");
  };

  const list = () => (
    <Box
      className={classes.Drawer}
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      <List>
        <ListItem key="home">
          <ListItemIcon>
            <HomeOutlinedIcon />
          </ListItemIcon>
          <Link className={classes.Link} to="/">
            {t("HOME")}
          </Link>
        </ListItem>
        {name === "guest" && (
          <ListItem key="login">
            <ListItemIcon>
              <LoginIcon />
            </ListItemIcon>
            <Link className={classes.Link} to="/auth">
              {t("LOGIN")}
            </Link>
          </ListItem>
        )}
        {adminUid === uid && (
          <ListItem key="build">
            <ListItemIcon>
              <BuildOutlinedIcon />
            </ListItemIcon>
            <Link className={classes.Link} to="/sheet-builder">
              {t("BUILD")}
            </Link>
          </ListItem>
        )}
        {name !== "guest" && (
          <>
            <ListItem key="logout">
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <Button variant="text" onClick={logoutHandler}>
                Logout
              </Button>
            </ListItem>
            <ListItem key="start-game">
              <ListItemIcon>
                <SportsEsportsIcon />
              </ListItemIcon>
              <Link className={classes.Link} to="/start-game">
                {t("START_GAME")}
              </Link>
            </ListItem>
          </>
        )}
      </List>
      <div>
        <Divider />
        <div className={classes.Switches}>
          <div className={classes.Language}>
            <LanguageSwitch />
          </div>
          <div className={classes.Theme}>
            <ThemeSwitch checked={themeMode} onChange={switchThemeMode} />
          </div>
        </div>
      </div>
    </Box>
  );

  return (
    <>
      <div className={classes.Container} onClick={() => toggleDrawer(true)}>
        <div className={classes.InnerContainer}>
          <div className={classes.InnerRow}></div>
        </div>
      </div>
      <Drawer
        anchor="left"
        open={showDrawer}
        onClose={() => toggleDrawer(false)}
      >
        {list()}
      </Drawer>
    </>
  );
}
