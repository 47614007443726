import React from "react";

// material-ui components
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import classes from "./PassiveSheet.module.css";

interface SheetData {
  categories: Array<string>;
}

const Sheet = (props: SheetData) => {
  return (
    <TableContainer className={classes.Container} component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 1 } }}>
            {props.categories.map((category) => (
              <TableCell
                key={category}
                align="center"
                sx={{ minWidth: 100, maxWidth: 200 }}
              >
                {category}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          ></TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Sheet;
