import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";

// i18n
import { useTranslation } from "utils/i18n";

// material-ui components
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";

// custom components
import SignUp from "components/SignUp/SignUp";
import Login from "components/Login/Login";
import Logo from "components/Logo/Logo";

// firebase
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";

// redux actions
import { setUser } from "store/authReducer";
import { setAlert } from "store/alertReducer";

import classes from "./Auth.module.css";

interface SignUpData {
  email: string;
  password: string;
  name: string;
}

interface LoginData {
  email: string;
  password: string;
}

const Auth = () => {
  const [signupOpen, setSignupOpen] = useState<boolean>(false);
  const [loginOpen, setLoginOpen] = useState<boolean>(false);

  const name = useSelector((state: RootState) => state.auth.name);

  const auth = getAuth();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    const userData = localStorage.getItem("loginData");
    if (userData) {
      const loginData = JSON.parse(userData);
      dispatch(setUser(loginData));
    }
  });

  const onSignUp = async (data: SignUpData) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      // Signed in
      await updateProfile(userCredential.user, {
        displayName: data.name,
      });

      setSignupOpen(false);

      onLogin({ email: data.email, password: data.password });
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("AUTH_ERROR: ", errorCode, " : ", errorMessage);
      // ..
    }
  };

  const onLogin = async (data: LoginData) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      // Logged in
      localStorage.setItem(
        "loginData",
        JSON.stringify({
          name: userCredential.user.displayName,
          uid: userCredential.user.uid,
        })
      );

      dispatch(
        setUser({
          name: userCredential.user.displayName,
          uid: userCredential.user.uid,
        })
      );
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      let errorTitle = t("ERROR");
      let errorContent = t("ERROR");
      switch (errorCode) {
        case "auth/wrong-password":
          errorTitle = t("WRONG_PASSWORD");
          errorContent = t("TRY_AGAIN");
          break;
        case "auth/too-many-requests":
          errorTitle = t("TOO_MANY_REQUESTS");
          errorContent = t("TRY_AGAIN_LATER");
          break;
        default:
          console.log("DEFAULT_CASE");
      }
      dispatch(
        setAlert({
          title: errorTitle,
          content: errorContent,
        })
      );
      console.log("AUTH_ERROR: ", errorCode, " : ", errorMessage);
      // ..
    }
    setLoginOpen(false);
  };

  useEffect(() => {
    if (name !== "guest") {
      navigate("/welcome");
    }
  }, [name, navigate]);

  return (
    <div className={classes.Container}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100vh",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
      ></div>

      <Grid container alignItems="center" justifyContent="center">
        <Dialog
          open={signupOpen}
          onBackdropClick={() => setSignupOpen(false)}
          fullWidth
        >
          <Grid container>
            <Grid item>
              <SignUp onSubmit={onSignUp} />
            </Grid>
          </Grid>
        </Dialog>
        <Dialog
          open={loginOpen}
          onBackdropClick={() => setLoginOpen(false)}
          fullWidth
        >
          <Grid container>
            <Grid item>
              <Login onSubmit={onLogin} />
            </Grid>
          </Grid>
        </Dialog>
        <Grid item xs={8} sm={6} md={4} xl={3}>
          <div className={classes.ButtonContainer}>
            <Button
              className={classes.Button}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => setSignupOpen((oldState) => !oldState)}
            >
              {t("SIGNUP")}
            </Button>
            <Button
              className={classes.Button}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => setLoginOpen((oldState) => !oldState)}
            >
              {t("LOGIN")}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Auth;
