import React, { useState } from "react";

// material-ui components
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// CSS for this component
import classes from "./Login.module.css";

// i18n
import { useTranslation } from "utils/i18n";

// typescript interface for props
// onSubmit is required function, and will be called with two props:
// "email" and "password"
// termsLink is optional prop
// if termsLink is provided, the link will be shown at the bottom of the
// card content (above the button)
interface LoginProps {
  onSubmit: Function;
}

const Login = (props: LoginProps) => {
  const { onSubmit } = props;

  // i18n
  const { t } = useTranslation();

  // states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // on submit props.onSubmit is called with email and password
  // should be then handled in the upper component
  const onSubmitHandler = () => {
    onSubmit({ email: email, password: password });
  };

  // email input handler
  const onChangeEmailHandler = (string: string) => {
    setEmail(string);
  };

  // password input handler
  const onChangePasswordHandler = (string: string) => {
    setPassword(string);
  };

  return (
    <Grid className={classes.Container} container>
      <Grid item xs={12} md={12} className={classes.Center}>
        <Card className={classes.Card}>
          <CardHeader title={t("LOGIN")} />

          <CardContent>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "25ch" },
                display: "flex",
                flexDirection: "column",
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                className={classes.Input}
                id="email-input"
                label={t("EMAIL")}
                variant="outlined"
                InputProps={{ type: "email", autoComplete: "email" }}
                onChange={(event) => onChangeEmailHandler(event.target.value)}
                value={email}
              />
              <TextField
                className={classes.Input}
                id="password-input"
                label={t("PASSWORD")}
                variant="outlined"
                InputProps={{ type: "password", autoComplete: "password" }}
                onChange={(event) =>
                  onChangePasswordHandler(event.target.value)
                }
                value={password}
              />
            </Box>
          </CardContent>
          <CardActions
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button color="secondary" onClick={onSubmitHandler}>
              {t("SUBMIT")}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Login;
