import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { RootState } from "store/store";

// material-ui components
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import classes from "./Sheet.module.css";

// import { useTranslation } from "utils/i18n";

interface SheetData {
  onChange: (data: Array<string>) => void;
  value: Array<string>;
}

const Sheet = (props: SheetData) => {
  const { onChange, value } = props;
  const categories = useSelector((state: RootState) => state.game.categories);

  const onChangeInputHandler = (val: string, index: number) => {
    const newValue = [...value];
    newValue[index] = val;
    onChange(newValue);
  };

  useEffect(() => {
    const newInputs = categories.map((category: string) => "");
    onChange(newInputs);
  }, [categories, onChange]);

  return (
    <div className={classes.Container}>
      <div className={classes.Paper}>
        {value.length > 1 && (
          <TableContainer>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {categories.map((category: string) => (
                    <TableCell
                      key={category}
                      align="center"
                      sx={{
                        minWidth: 100,
                        fontFamily: "cursive",
                        fontSize: "1.2em",
                        textDecoration: "underline",
                      }}
                      className={classes.Cell}
                    >
                      {category}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {categories.map((category: string, index: number) => {
                    return (
                      <TableCell
                        key={index.toString()}
                        align="center"
                        sx={{
                          minWidth: 100,
                          fontFamily: "cursive",
                          fontSize: "1.2em",
                        }}
                        className={classes.Cell}
                      >
                        <TextField
                          //label={category}
                          variant="standard"
                          onChange={(e) =>
                            onChangeInputHandler(e.target.value, index)
                          }
                          value={value[index]}
                          inputProps={{
                            style: {
                              fontFamily: "cursive",
                              fontWeight: "bold",
                            },
                          }}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default Sheet;
