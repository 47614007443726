import React, { useState, useEffect, useCallback } from "react";

import { useSelector } from "react-redux";
import { RootState } from "store/store";

import { useNavigate } from "react-router-dom";

// firebase
import {
  doc,
  onSnapshot,
  setDoc,
  getDoc,
  collection,
} from "firebase/firestore";
import { db } from "utils/firebase";

import { adminUid } from "constants/constants";

// custom components
import Sheet from "components/Sheet/Sheet";
import BuzzerButton from "components/CustomButtons/BuzzerButton/BuzzerButton";

// material-ui components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { alphabet } from "constants/constants";

import classes from "./Game.module.css";

const sheetRef = collection(db, "sheets");

const Game = () => {
  const [currentLetter, setCurrentLetter] = useState<string>("");
  const [gameStopped, setGameStopped] = useState<boolean>(false);
  const [inputs, setInputs] = useState<Array<string>>([""]);
  const [allFilled, setAllFilled] = useState<boolean>(false);

  const uid = useSelector((state: RootState) => state.auth.uid);
  const name = useSelector((state: RootState) => state.auth.name);
  const gameId = useSelector((state: RootState) => state.game.gameId);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("GAME_STOPPED: ", gameStopped);
    if (!gameStopped) {
      findRandomLetterHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStopped]);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "sheets", gameId), (doc) => {
      const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
      console.log(source, " data: ", doc.data());
      const newData = doc.data();
      if (newData) {
        if (newData.currentLetter) {
          setCurrentLetter(newData.currentLetter);
        }
        if (newData.gameStopped) {
          setGameStopped(true);
        } else {
          setGameStopped(false);
        }
      }
    });
    return () => {
      unsub && unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendSheetData = useCallback(async () => {
    console.log("SEND_SHEET!");
    if (uid && name !== "guest") {
      console.log("SEND_SHEET_DATA: ", name, " : ", inputs);
      const dataObj = {
        results: {
          [currentLetter]: {
            [name + "-" + uid]: inputs,
          },
        },
      };
      try {
        await setDoc(doc(sheetRef, gameId), dataObj, { merge: true });
      } catch (error) {
        console.log("SEND_DATA_ERROR: ", error);
      }
    }
  }, [uid, currentLetter, inputs, gameId, name]);

  useEffect(() => {
    let filled = true;
    inputs.forEach((input: string) => {
      filled = filled && input.length > 1;
    });
    setAllFilled(filled);
  }, [inputs]);

  useEffect(() => {
    if (gameStopped) {
      if (adminUid !== uid) {
        sendSheetData();
      }
      navigate("/score-board");
    }
  }, [gameStopped, sendSheetData, navigate, uid]);

  const findRandomLetterHandler = useCallback(async () => {
    console.log("NEW_LETTER!!!");
    try {
      let usedLetters = [];
      const respData = await getDoc(doc(sheetRef, gameId));
      if (respData.exists()) {
        const gameData = respData.data();

        usedLetters = gameData.usedLetters ? gameData.usedLetters : [];

        let letter;
        if (!gameData.gameRunning && adminUid === uid && !gameStopped) {
          do {
            const random = Math.floor(Math.random() * 26);
            letter = alphabet[random];
          } while (usedLetters.includes(letter));
          const newLetters = [...usedLetters, letter];
          const docRef = await setDoc(
            doc(sheetRef, gameId),
            {
              currentLetter: letter,
              usedLetters: newLetters,
              gameRunning: true,
            },
            { merge: true }
          );
        } else {
          letter = gameData.currentLetter;
        }
        setCurrentLetter(letter);
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  }, [gameId]);

  const submitSheetHandler = async () => {
    try {
      const docRef = await setDoc(
        doc(sheetRef, gameId),
        {
          gameStopped: true,
          gameRunning: false,
        },
        { merge: true }
      );
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  const onChangeSheetHandler = useCallback(
    (data: Array<string>) => {
      setInputs(data);
    },
    [setInputs]
  );

  return (
    <div className={classes.Container}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} className={classes.HeadingGrid}>
          <div className={classes.HeadingContainer}>
            <Typography variant="h1" className={classes.Heading}>
              {currentLetter}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Sheet onChange={onChangeSheetHandler} value={inputs} />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
          <BuzzerButton
            disabled={adminUid === uid ? false : !allFilled}
            onClick={submitSheetHandler}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Game;
