import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setAlert, clearAlert } from "store/alertReducer";

import { db } from "utils/firebase";
import { collection, doc, setDoc } from "firebase/firestore";

// material-ui components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

// custom components
import PassiveSheet from "components/PassiveSheet/PassiveSheet";

// i18n
import { useTranslation } from "utils/i18n";

import classes from "./SheetBuilder.module.css";

import { alphabet } from "constants/constants";

const SheetBuilder = () => {
  const [category, setCategory] = useState<string>("");
  const [categories, setCategories] = useState<Array<string>>([]);
  const [rounds, setRounds] = useState<number>(1);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const successHandler = () => {
    dispatch(clearAlert());
    setCategories([]);
    setCategory("");
    navigate("/start-game");
  };

  const onChangeRoundsHandler = (value: string) => {
    const r = parseInt(value);
    setRounds(r);
  };

  const saveSheetHandler = async () => {
    let idString = "";
    for (let i = 0; i < 8; i++) {
      const index = Math.floor(Math.random() * 26);
      idString = idString + alphabet[index];
    }

    try {
      const sheetRef = collection(db, "sheets");
      const docRef = await setDoc(doc(sheetRef, idString), {
        categories: categories,
        gameId: idString,
        rounds: rounds,
        gameStarted: false,
      });

      dispatch(
        setAlert({
          title: t("SHEET_SAVED"),
          content: (
            <>
              {t("YOUR_SHEET_ID")}
              <strong> {idString} </strong>
            </>
          ),
          submit: successHandler,
        })
      );
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const addCategoryHandler = () => {
    if (categories.length < 8) {
      const oldCategories = [...categories];
      oldCategories.push(category);
      setCategories(oldCategories);
      setCategory("");
    }
  };

  const onChangeInputHandler = (value: string) => {
    setCategory(value);
  };

  return (
    <div className={classes.Container}>
      <Grid
        container
        className={classes.GridContainer}
        justifyContent="center"
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            id="outlined-basic"
            label={t("CATEGORY")}
            variant="outlined"
            onChange={(e) => onChangeInputHandler(e.target.value)}
            value={category}
          />
          <Button
            variant="contained"
            color="success"
            disabled={category.length < 2}
            onClick={addCategoryHandler}
          >
            {t("ADD")}
          </Button>
        </Grid>
        <Grid item></Grid>
        <Grid
          item
          xs={8}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            id="outlined-basic"
            label={t("ROUNDS")}
            variant="outlined"
            onChange={(e) => onChangeRoundsHandler(e.target.value)}
            value={rounds}
            type="number"
          />
        </Grid>
        <Grid item xs={12} className={classes.SheetContainer}>
          <PassiveSheet categories={categories} />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="success"
            onClick={saveSheetHandler}
            disabled={categories.length < 3}
          >
            {t("SAVE")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SheetBuilder;
