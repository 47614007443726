// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA0pBgPevVpVTlKyF5MKSfuxym4eb82jSo",
  authDomain: "city-country-river.firebaseapp.com",
  databaseURL:
    "https://city-country-river-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "city-country-river",
  storageBucket: "city-country-river.appspot.com",
  messagingSenderId: "1065296436049",
  appId: "1:1065296436049:web:5c1550bdc6ad7a0c309170",
  measurementId: "G-GXQXDEW5DZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
