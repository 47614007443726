import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";

// firebase
import { doc, onSnapshot, collection } from "firebase/firestore";
import { db } from "utils/firebase";

// redux
import { useSelector } from "react-redux";
import { RootState } from "store/store";

import classes from "./EndGameScreen.module.css";

// i18n
import { useTranslation } from "utils/i18n";

import winnerImg1 from "assets/img/backgrounds/winner-screen-1.jpg";
import winnerImg2 from "assets/img/backgrounds/winner-screen-2.jpg";
import winnerImg3 from "assets/img/backgrounds/winner-screen-3.jpg";

const sheetRef = collection(db, "sheets");

interface ScoreObject {
  name: string;
  score: number;
}

const EndGameScreen = () => {
  const [userScore, setUserScore] = useState<Array<ScoreObject>>();

  const { t } = useTranslation();

  //const uid = useSelector((state: RootState) => state.auth.uid);
  const name = useSelector((state: RootState) => state.auth.name);
  const gameId = useSelector((state: RootState) => state.game.gameId);
  //const categories = useSelector((state: RootState) => state.game.categories);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "sheets", gameId), (doc) => {
      const newData = doc.data();
      if (newData) {
        if (newData.scores) {
          const playerScoresArray: Array<ScoreObject> = [];
          for (let letter in newData.scores) {
            for (let player of newData.scores[letter]) {
              let userScoreObject = { name: player.name, score: 0 };
              let points = 0;
              for (let score of player.points) {
                points = points + score;
              }
              userScoreObject.score = points;
              const idx = playerScoresArray.findIndex(
                (uScoreO: ScoreObject) => uScoreO.name === userScoreObject.name
              );
              if (idx >= 0) {
                playerScoresArray[idx].score += points;
              } else {
                playerScoresArray.push(userScoreObject);
              }
            }
          }
          const sortedScoreArray: Array<ScoreObject> = [];
          for (let i = 0; i < playerScoresArray.length; i++) {
            let index = sortedScoreArray.length;
            if (sortedScoreArray.length > 0) {
              for (let j = 0; j < sortedScoreArray.length; j++) {
                if (sortedScoreArray[j].score < playerScoresArray[i].score) {
                  index = index < j ? index : j;
                }
              }
              sortedScoreArray.splice(index, 0, playerScoresArray[i]);
            } else {
              sortedScoreArray.push(playerScoresArray[i]);
            }
          }

          setUserScore(sortedScoreArray);
        }
      }
    });
    return () => {
      unsub && unsub();
    };
  }, []);

  return (
    <div
      style={{
        backgroundImage:
          "url(" +
          (userScore && userScore[0].name === name ? winnerImg2 : winnerImg1) +
          ")",
      }}
      className={classes.Container}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          {userScore && userScore[0].name === name && (
            <div className={classes.WinnerContainer}>
              <Typography className={classes.WinnerHeading} variant="h2">
                {t("CONGRATS")}!
              </Typography>
              <Typography className={classes.WinnerHeading} variant="h3">
                {t("YOU_WIN")}!
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper className={classes.Paper}>
            <TableContainer>
              {userScore && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("PLAYER")}</TableCell>
                      <TableCell>{t("SCORE")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userScore.map((user: ScoreObject, key: number) => {
                      return (
                        <TableRow key={user.name + key}>
                          <TableCell
                            style={{
                              fontWeight: key === 0 ? "bold" : "normal",
                            }}
                            align="left"
                          >
                            {user.name}
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: key === 0 ? "bold" : "normal",
                            }}
                            align="left"
                            key={"score" + key}
                          >
                            {user.score}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default EndGameScreen;
