import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import { fetchGame } from "store/gameReducer";

import { useTranslation } from "utils/i18n";

import classes from "./StartGame.module.css";
import { RootState } from "store/store";

const StartGame = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [id, setId] = useState<string>("");

  const categories = useSelector((state: RootState) => state.game.categories);

  const onChangeIdHandler = (value: string) => {
    setId(value);
  };

  const onSubmitHandler = () => {
    dispatch(fetchGame(id));
    navigate("/wait-game");
  };

  // useEffect(() => {
  //   if (categories.length > 1) {
  //     navigate("/wait-game");
  //   }
  // }, [categories, navigate]);

  return (
    <div className={classes.Container}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100vh",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,0.7)",
        }}
      ></div>
      <Grid container justifyContent="center" style={{ zIndex: 10 }}>
        {/* <Grid item xs={12}>
          <Typography
            variant="h1"
            sx={{
              textAlign: "center",
              color: "#d0d0d0",
              textShadow: "2px 2px 2px black",
            }}
          >
            {t("CITY")} {t("COUNTRY")} {t("RIVER")}
          </Typography>
        </Grid> */}
        <Grid sx={{ marginTop: "5%" }} item xs={8} sm={6} md={4} lg={3}>
          <Paper className={classes.Paper}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={8}>
                <Typography variant="body1">{t("INPUT_ID_HERE")}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  variant="outlined"
                  label={t("YOUR_SHEET_ID")}
                  value={id}
                  onChange={(e) => onChangeIdHandler(e.target.value)}
                />
              </Grid>
              <Grid item xs={8}>
                <Button
                  variant="contained"
                  onClick={onSubmitHandler}
                  disabled={id.length !== 8}
                >
                  {t("SUBMIT")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default StartGame;
