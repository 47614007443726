import React from "react";

// i18n
import { useTranslation } from "utils/i18n";

// CSS
import classes from "./Home.module.css";

// material-ui components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// custom components
import Logo from "components/Logo/Logo";

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.Container}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100vh",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
      ></div>

      <Grid container justifyContent="center" style={{ zIndex: 10 }}>
        <Grid item xs={12}>
          <Typography
            className={classes.Heading}
            style={{
              textAlign: "center",
              fontWeight: "normal",
              color: "#c0c0c0",
            }}
            variant="h1"
          >
            {t("WELCOME")}
          </Typography>
        </Grid>
        {/*  <Grid item xs={12} md={10} xl={8} style={{ minWidth: "500px" }}>
          <Typography
            className={classes.Heading}
            style={{ textAlign: "left", fontWeight: "bold", color: "#c0c0c0" }}
            variant="h1"
          >
            {t("CITY")}
          </Typography>
          <Typography
            className={classes.Heading}
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: "#c0c0c0",
            }}
            variant="h1"
          >
            {t("COUNTRY")}
          </Typography>
          <Typography
            className={classes.Heading}
            style={{ textAlign: "right", fontWeight: "bold", color: "#c0c0c0" }}
            variant="h1"
          >
            {t("RIVER")}
          </Typography>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default Welcome;
