import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState } from "store/store";

import { adminUid } from "constants/constants";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// firebase
import { doc, onSnapshot, setDoc, collection } from "firebase/firestore";
import { db } from "utils/firebase";

import { useTranslation } from "utils/i18n";

import classes from "./WaitGame.module.css";

const sheetRef = collection(db, "sheets");

const WaitGame = () => {
  const [gameStarted, setGameStarted] = useState<boolean>(false);

  const gameId = useSelector((state: RootState) => state.game.gameId);
  const uid = useSelector((state: RootState) => state.auth.uid);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const startGameHandler = async () => {
    const dataObj = {
      gameStarted: true,
    };
    try {
      await setDoc(doc(sheetRef, gameId), dataObj, { merge: true });
    } catch (error) {
      console.log("SEND_DATA_ERROR: ", error);
    }
  };

  useEffect(() => {
    let unsub: Function | null = null;
    if (gameId) {
      unsub = onSnapshot(doc(db, "sheets", gameId), (doc) => {
        // const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
        // console.log(source, " data: ", doc.data());
        const newData = doc.data();
        if (newData) {
          if (newData.gameStarted) {
            setGameStarted(true);
          }
        }
      });
    }
    return () => {
      unsub && unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId, db]);

  useEffect(() => {
    if (gameStarted) {
      navigate("/game");
    }
  }, [gameStarted]);

  return (
    <div className={classes.Container}>
      {adminUid === uid ? (
        <Button variant="contained" size="large" onClick={startGameHandler}>
          {t("START_GAME")}
        </Button>
      ) : (
        <Typography variant="h2" className={classes.Heading}>
          {t("WAIT_FOR_BEGIN")}...
        </Typography>
      )}
    </div>
  );
};

export default WaitGame;
